import { useCallback, useEffect, useState } from "react";
import './style.scss';
import video_uri from '../assets/video/pre_sale_video.mp4';
import tank_logo from '../assets/images/common/tank.png';
import Button from "../Components/button";
import Dropdown from 'react-bootstrap/Dropdown';
import networkList from "./networkList";
import swal from "sweetalert";
import { Link, redirect } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";

import { batch, useDispatch, useSelector } from "react-redux";
import { BigNumber, ethers } from 'ethers';
import { useProvider } from "./Hooks/useProvider";
import { setAccount, setBNBPrice, setChainId, setFromAmount, setIsApproved, setIsSwitch, setLoadingBalance, setLoadingBuy, setLoadingPage, setMafBalance, setNetwork, setNetworkCode, setNetworkName, setProvider, setSigner, setToAmount, setTokenBalance, setTokenName, setTokenPrice, setTotalInvestment } from "../store/Slicers/data";
import { useSigner } from "./Hooks/useSigner";
import { BEP20BUSD_CONTRACT, BEP20MAF_CONTRACT, BEP20PRESALE_CONTRACT, BEP20USDT_CONTRACT } from "../Constants/contract";
import { BEP20BUSD_ABI, BEP20MAF_ABI, BEP20PRESALE_ABI, BEP20USDT_ABI } from "../Constants/abi";
import { setBepBUSDToken, setBepMAFToken, setBepPresaleContract, setBepUSDTToken } from "../store/Slicers/contracts";
import Chains from "./chains";
import convertChainId from "./Utils/convertChainId";
import getCoinPrice from "./Utils/getCoinPrice";
import { setAllowanceVal } from "../store/Slicers/data";
import {isMobile, isTablet} from 'react-device-detect';


const Presale = () => {
    const dispatch = useDispatch();
    const provider = useProvider();
    const signer = useSigner();
    const totalInvestment = useSelector((state) => state.data.totalInvestment);
    const bnbPrice = useSelector((state) => state.data.bnbPrice);
    const account = useSelector((state) => state.data.account);
    const tokenName = useSelector((state) => state.data.tokenName);
    const isSwitch = useSelector((state) => state.data.isSwitch);
    const isApproved = useSelector((state) => state.data.isApproved);
    const networkName = useSelector((state) => state.data.networkName);
    const network = useSelector((state) => state.data.network);
    const networkCode = useSelector((state) => state.data.networkCode);
    const chainId = useSelector((state) => state.data.chainId);
    const toAmount = useSelector((state) => state.data.toAmount);
    const fromAmount = useSelector((state) => state.data.fromAmount);
    const loadingBuy = useSelector((state) => state.data.loadingBuy);
    const loadingBalance = useSelector((state) => state.data.loadingBalance);
    const loadingPage = useSelector((state) => state.data.loadingPage);
    const tokenBalance = useSelector((state) => state.data.tokenBalance);
    const tokenPrice = useSelector((state) => state.data.tokenPrice);
    const mafBalance = useSelector((state) => state.data.mafBalance);
    const allowanceVal = useSelector((state) => state.data.allowanceVal);


    const bepPresaleContract = useSelector((state) => state.contracts.bepPresaleContract);
    const bepMAFToken = useSelector((state) => state.contracts.bepMAFToken);
    const bepBUSDToken = useSelector((state) => state.contracts.bepBUSDToken);
    const bepUSDTToken = useSelector((state) => state.contracts.bepUSDTToken);



    if (window.ethereum != null) {
        window.ethereum.on('chainChanged', function (chx) {
            if (convertChainId(chx) != Chains['BEP20'].chainId) {
                dispatch(setIsSwitch(true));
                return;
            }
            if (chx != chainId) {
                dispatch(setIsSwitch(true));
                return;
            }
            dispatch(setIsSwitch(false));
        });

        window.ethereum.on('accountsChanged', function (accounts) {
            dispatch(setAccount(accounts[0]));
        })
    }


    const handleSwitchNetwork = useCallback(() => {

        provider.send('wallet_switchEthereumChain', [{
            chainId: Chains['BEP20'].chainId
        }]).then(() => {
            dispatch(setIsSwitch(false));
            updateMafBalance();
            updateCoinBalance(account)
        }).catch((err) => {
            switch (err.code) {
                case 4902:
                    try {
                        window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: Chains['BEP20'].chainId,
                                    chainName: Chains['BEP20'].name,
                                    rpcUrls: [Chains['BEP20'].uri] /* ... */,
                                },
                            ],
                        });
                    } catch (erra2) {
                        console.log(erra2);
                        swal("Error", "Unknow error", 'error');
                    }
                    break;
                case -32002:
                    swal("Error", "Waiting for request, please open metamask window", 'error');
                    break;
                default:
                    swal("Error", err.message, 'error');
                    break;

            }
        });
    })

    const updateCoinBalance = async () => {
        if (!provider) return;
        dispatch(setLoadingBalance(true));
        switch (network) {
            case 'usdt-bep20':
                bepUSDTToken.balanceOf(account).then((b) => {
                    dispatch(setTokenBalance(ethers.utils.formatEther(b)));
                    dispatch(setLoadingBalance(false));
                }).catch((err) => console.log(err));
                break;
            case 'busd-bep20':
                bepBUSDToken.balanceOf(account).then((b) => {
                    dispatch(setTokenBalance(ethers.utils.formatEther(b)));
                    dispatch(setLoadingBalance(false));
                }).catch((err) => console.log(err));
                break;
            case 'bnb-bep20':
                provider.getBalance(account).then((b) => {
                    dispatch(setTokenBalance(ethers.utils.formatEther(b)));
                    dispatch(setLoadingBalance(false));
                }).catch((err) => console.log(err));
                break;
        }

    }
    function calculateFromAmount(val) {
        dispatch(setIsApproved(false))
        if (val < 0) {
            dispatch(setToAmount(0));
            return;
        }
        var from = parseFloat(val.toString().replace(',', '.'));
        dispatch(setFromAmount(val));
        var to = 0;
        if (from == 0) return;
        let ca = calculateAmount(val);
        to = (from / .01) * ca.mul;
        dispatch(setToAmount(to.toFixed(ca.digit)));

        if (tokenName == 'BNB' || tokenName == 'ETH') {
        } else {
            if (Number(allowanceVal) < Number(to)) {
                dispatch(setIsApproved(true))
            }
        }
    }
    function calculateToAmount(val) {
        dispatch(setIsApproved(false))
        if (val < 0) {
            dispatch(setFromAmount(0));
            return;
        }
        var to = parseFloat(val.toString().replace(',', '.'));
        dispatch(setToAmount(val));
        var from = 0;
        if (to == 0) return;
        let ca = calculateAmount(val);
        from = (to * .01) / ca.mul;
        dispatch(setFromAmount(from.toFixed(ca.digit)));

        if (tokenName == 'BNB' || tokenName == 'ETH') {
        } else {
            if (Number(allowanceVal) < Number(to)) {
                dispatch(setIsApproved(true))
            }
        }
    }

    function calculateAmount(val) {
        let mul = 1;
        let digit = 2;
        switch (network) {
            case 'bnb-bep20':
                mul = tokenPrice;
                digit = 4;
                break;

            default:
                digit = 2;
                mul = 1;
                break;
        }
        return { 'digit': digit, 'mul': mul }

    }

    const handleNetwork = useCallback(async (e) => {
        dispatch(setFromAmount(0))
        dispatch(setToAmount(0))
        dispatch(setIsApproved(false));
        if (e.indexOf('-') == -1) {
            alert("Unknow network");
            return;

        }
        let spNetwork = e.split('-');
        dispatch(setNetworkCode(spNetwork[1].toUpperCase()))
        dispatch(setTokenName(spNetwork[0].toUpperCase()));
        dispatch(setNetworkName(spNetwork[0].toUpperCase() + " (" + spNetwork[1].toUpperCase() + ")"));
        dispatch(setNetwork(e));

        const getChain = Chains[spNetwork[1].toUpperCase()];

        if (!getChain) {
            return alert("Unknow network");
        }

        setChainId(convertChainId(getChain.chainId));
        if (convertChainId(getChain.chainId) != convertChainId(provider.provider.chainId)) {
            setIsSwitch(true);
        } else {
            setIsSwitch(false);
            setLoadingBalance(true);
            var contract;

            switch (e) {
                case 'usdt-bep20':
                    dispatch(setLoadingBuy(true));
                    bepUSDTToken.balanceOf(account).then((b) => { dispatch(setTokenBalance(ethers.utils.formatEther(b))); dispatch(setLoadingBalance(false)); });
                    checkAllowance(e);
                    dispatch(setLoadingBuy(false));
                    break;
                case 'busd-bep20':
                    dispatch(setLoadingBuy(true));
                    bepBUSDToken.balanceOf(account).then((b) => { dispatch(setTokenBalance(ethers.utils.formatEther(b))); dispatch(setLoadingBalance(false)); });
                    checkAllowance(e);
                    dispatch(setLoadingBuy(false));
                    break;
                case 'bnb-bep20':
                    provider.getBalance(account).then((b) => { dispatch(setTokenBalance(ethers.utils.formatEther(b))); dispatch(setLoadingBalance(false)); });
                    break;
            }
        }
        bepMAFToken.balanceOf(account).then((b) => {
            dispatch(setMafBalance(ethers.utils.formatEther(b)));
        }).catch((err) => console.log("maf coin", err));
    });



    function connect() {
        if(isMobile || isTablet) {
            
            window.location.href = 'https://metamask.app.link/dapp/metaversearmyforces.com';
            return;
        }

        if (!window.ethereum) {
            swal({
                title : '',
                text : 'Metamask is not installed on your device. Please install metamask!',
                buttons: {
                    cancel : true,

                    install : {
                        text : 'Install Metamask',
                        value : true,
                        closeModal : true,
                        className : 'success'
                    },
                    
                }
            }).then((selected) => {
                if(selected) {
                    window.open('https://metamask.io/download/');
                }
            })
            return;
        }
        if (!provider) return;
        provider.send('eth_requestAccounts', [{
            chainId: Chains['BEP20'].chainId,
        }])
            .then((accounts) => {
                dispatch(setProvider(provider));
                const signer = provider.getSigner();
                const account = accounts[0];
                dispatch(setAccount(account))
                dispatch(setSigner(signer));

                const bepPresaleContract = new ethers.Contract(BEP20PRESALE_CONTRACT, BEP20PRESALE_ABI, signer);
                const bepMAFToken = new ethers.Contract(BEP20MAF_CONTRACT, BEP20MAF_ABI, signer);
                const bepBUSDToken = new ethers.Contract(BEP20BUSD_CONTRACT, BEP20BUSD_ABI, signer);
                const bepUSDTToken = new ethers.Contract(BEP20USDT_CONTRACT, BEP20USDT_ABI, signer);
                //const ercUSDTToken = new ethers.Contract(BEP20MAF_CONTRACT, BEP20MAF_ABI, provider);

                dispatch(setBepPresaleContract(bepPresaleContract));
                dispatch(setBepMAFToken(bepMAFToken));
                dispatch(setBepBUSDToken(bepBUSDToken));
                dispatch(setBepUSDTToken(bepUSDTToken));

                signer.getChainId().then(chainId => {

                    if (chainId != Chains['BEP20'].chainId) {
                        dispatch(setChainId(convertChainId(Chains['BEP20'].chainId)));
                        dispatch(setIsSwitch(true))
                    } else {
                        dispatch(setChainId(convertChainId(chainId)));
                    }
                    updateMafBalance();
                });
            })
            .catch((error) => {
                switch (error.code) {
                    case -32002:
                        swal("Error", "Waiting for request, please open metamask window", 'error');
                        break;
                    default:
                        swal("Error", error.message, 'error');
                        break;

                }
            });
    }

    const approve_click = async () => {
        if (!provider) return;
        if (account == null || account == "") return;
        if (Number(toAmount) <= 0) {
            swal("Error", "Please enter a valid amount", "error");
            return;
        }
        if (Number(tokenBalance) <= 0) {
            swal("Error", "insufficient balance", "error");
            return;
        }
        if (Number(tokenBalance) < Number(fromAmount)) {
            swal("Error", "insufficient balance", "error");
            return;
        }
        dispatch(setLoadingBuy(true));
        let amount = parseFloat(fromAmount);
        let etherAmount = ethers.utils.parseUnits(amount.toString(), 18);
        switch (network) {
            case 'busd-bep20':
                try {
                    let txn = await bepBUSDToken.approve(BEP20PRESALE_CONTRACT, etherAmount);
                    await txn.wait();
                    swal("Approved", "Authorization granted, you can buy now", "info");
                    dispatch(setIsApproved(false));
                    dispatch(setLoadingBuy(false));
                }
                catch (err) {
                    dispatch(setLoadingBuy(false));
                    dispatch(setLoadingBuy(false));
                    if (err.code == 'ACTION_REJECTED') {
                        swal('Info', 'You canceled the purchase.', 'info')
                        return;
                    } else {
                        swal('Info', err.message, 'info')
                        return;
                    }
                }
                break;

            case 'usdt-bep20':
                try {
                    let txn = await bepUSDTToken.approve(BEP20PRESALE_CONTRACT, etherAmount);
                    await txn.wait();
                    swal("Approved", "Authorization granted, you can buy now", "info");
                    dispatch(setIsApproved(false));
                    dispatch(setLoadingBuy(false));
                }
                catch (err) {
                    dispatch(setLoadingBuy(false));
                    dispatch(setLoadingBuy(false));
                    if (err.code == 'ACTION_REJECTED') {
                        swal('Info', 'You canceled the purchase.', 'info')
                        return;
                    } else {
                        swal('Info', err.message, 'info')
                        return;
                    }
                }
                break;
        }
    }

    const presale = async () => {
        if (!provider) return;
        if (toAmount == null || toAmount == "") {
            swal("Error", "Please enter a valid amount", "error");
            return;
        }
        if (fromAmount == null || fromAmount == "") {
            swal("Error", "Please enter a valid amount", "error");
            return;
        }
        if (account == null || account == "") return;
        if (Number(toAmount) <= 0) {
            swal("Error", "Please enter a valid amount", "error");
            return;
        }
        if (Number(tokenBalance) <= 0) {
            swal("Error", "insufficient balance", "error");
            return;
        }
        if (Number(tokenBalance) < Number(fromAmount)) {
            swal("Error", "insufficient balance", "error");
            return;
        }
        //dispatch(setLoadingBuy(true));
        let amount = parseFloat(fromAmount);
        let etherAmount = ethers.utils.parseUnits(amount.toString(), 18);
        const signer = provider.getSigner();
        let getAddress = await signer.getAddress();
        dispatch(setLoadingBuy(true));
        switch (network) {
            case 'busd-bep20':
                try {
                    var allow = await bepBUSDToken.allowance(getAddress, BEP20PRESALE_CONTRACT);
                    if (Number(ethers.utils.formatEther(allow)) < Number(amount)) {
                        swal({
                            title: "Requires permission to spend",
                            text: "In order for the transaction to take place, you must confirm your BUSD transfer to the contract.",
                            icon: "warning",
                            buttons: {
                                info: {
                                    text: 'More Info',
                                    value: 'info',
                                    closeModal: false,

                                },
                                approve: {
                                    text: 'Approve',
                                    value: 'approve',
                                    className: 'success'
                                },
                                cancel: 'Cancel'
                            },
                            dangerMode: true,
                        }).then(async function (value) {

                            switch (value) {
                                case 'info':
                                    window.open('https://support.metamask.io/hc/en-us/articles/6174898326683-What-is-a-token-approval-', '_blank');
                                    break;
                                case 'cancel':
                                    dispatch(setLoadingBuy(false));
                                    break;
                                case 'approve':
                                    try {
                                        let approve = await bepBUSDToken.approve(BEP20PRESALE_CONTRACT, etherAmount);
                                        await approve.wait();
                                        swal("Approved", "Authorization granted, you can buy now", "info");
                                        dispatch(setLoadingBuy(false));
                                    } catch (err) {
                                        dispatch(setLoadingBuy(false));
                                        if (err.code == 'ACTION_REJECTED') {
                                            swal('Info', 'You canceled the purchase.', 'info')
                                            return;
                                        } else {
                                            swal('Info', err.message, 'info')
                                            return;
                                        }
                                    }
                                    break;
                            }
                            return;
                        });
                    } else {
                        var txn = await bepPresaleContract.buyBUSD(etherAmount);
                        await txn.wait();
                        swal("Info", "Purchase done. By adding the token to your MetaMask wallet, you can see your asset.", 'info');
                        dispatch(setLoadingBuy(false));
                        dispatch(setFromAmount(0));
                        dispatch(setToAmount(0));
                        updateMafBalance();
                        updateCoinBalance();
                    }

                } catch (err) {
                    dispatch(setLoadingBuy(false));
                    if (err.code == 'ACTION_REJECTED') {
                        swal('Info', 'You canceled the purchase.', 'info')
                        return;
                    } else {
                        swal('Info', err.message, 'info')
                        return;
                    }
                }
                break;
            case 'usdt-bep20':
                try {
                    var allow = await bepUSDTToken.allowance(getAddress, BEP20PRESALE_CONTRACT);
                    if (Number(ethers.utils.formatEther(allow)) < Number(amount)) {
                        swal({
                            title: "Requires permission to spend",
                            text: "In order for the transaction to take place, you must confirm your USDT transfer to the contract.",
                            icon: "warning",
                            buttons: {
                                info: {
                                    text: 'More Info',
                                    value: 'info',
                                    closeModal: false,

                                },
                                approve: {
                                    text: 'Approve',
                                    value: 'approve',
                                    className: 'success'
                                },
                                cancel: 'Cancel'
                            },
                            dangerMode: true,
                        }).then(async function (value) {

                            switch (value) {
                                case 'info':
                                    window.open('https://support.metamask.io/hc/en-us/articles/6174898326683-What-is-a-token-approval-', '_blank');
                                    break;
                                case 'cancel':
                                    dispatch(setLoadingBuy(false));
                                    break;
                                case 'approve':
                                    try {
                                        let approve = await bepUSDTToken.approve(BEP20PRESALE_CONTRACT, etherAmount);
                                        await approve.wait();
                                        swal("Approved", "Authorization granted, you can buy now", "info");
                                        dispatch(setLoadingBuy(false));
                                    } catch (err) {
                                        dispatch(setLoadingBuy(false));
                                        if (err.code == 'ACTION_REJECTED') {
                                            swal('Info', 'You canceled the purchase.', 'info')
                                            return;
                                        } else {
                                            swal('Info', err.message, 'info')
                                            return;
                                        }
                                    }
                                    break;
                            }
                            return;
                        });
                    } else {
                        var txn = await bepPresaleContract.buyUSDT(etherAmount);
                        await txn.wait();
                        swal("Info", "Purchase done. By adding the token to your MetaMask wallet, you can see your asset", 'info');
                        dispatch(setLoadingBuy(false));
                        dispatch(setFromAmount(0));
                        dispatch(setToAmount(0));
                        updateMafBalance();
                        updateCoinBalance();
                    }

                } catch (err) {
                    dispatch(setLoadingBuy(false));
                    if (err.code == 'ACTION_REJECTED') {
                        swal('Info', 'You canceled the purchase.', 'info')
                        return;
                    } else {
                        swal('Info', err.message, 'info')
                        return;
                    }
                }
                break;

            case 'bnb-bep20':
                try {
                    var txn = await bepPresaleContract.buyBNB({ value: etherAmount, gasLimit: 2100000 });
                    await txn.wait();
                    swal("Info", "Purchase done. By adding the token to your MetaMask wallet, you can see your asset", 'info');
                    dispatch(setLoadingBuy(false));
                    dispatch(setFromAmount(0));
                    dispatch(setToAmount(0));
                    updateMafBalance();
                    updateCoinBalance();
                } catch (err) {
                    dispatch(setLoadingBuy(false));
                    if (err.code == 'ACTION_REJECTED') {
                        swal('Info', 'You canceled the purchase.', 'info')
                        return;
                    } else {
                        swal('Info', err.message, 'info')
                        return;
                    }
                }
                break;
        }
    }

    async function checkAllowance(_network) {
        if (!provider) return;
        switch (_network) {
            case 'busd-bep20':
                let allow = await bepBUSDToken.allowance(account, BEP20PRESALE_CONTRACT);
                dispatch(setAllowanceVal(allow));
                if (Number(allow) < Number(fromAmount)) {
                    //dispatch(setIsApproved(true));
                }
                break;
        }
    }

    async function updateMafBalance() {
        if (!provider) return;
        if (!account) return;
        bepMAFToken.balanceOf(account).then((b) => dispatch(setMafBalance(ethers.utils.formatEther(b))));
    }




    useEffect(() => {
        if(isMobile || isTablet) {
            
            window.location.href = 'https://metamask.app.link/dapp/metaversearmyforces.com';
            return;
        }

        if (!window.ethereum) {
            swal({
                title : '',
                text : 'Metamask is not installed on your device. Please install metamask!',
                buttons: {
                    cancel : true,

                    install : {
                        text : 'Install Metamask',
                        value : true,
                        closeModal : true,
                        className : 'success'
                    },
                    
                }
            }).then((selected) => {
                if(selected) {
                    window.open('https://metamask.io/download/');
                }
            })
            return;
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        dispatch(setProvider(provider));
        getCoinPrice('BNB').then((bnbPrice) => {
            dispatch(setBNBPrice(bnbPrice))
            dispatch(setTokenPrice(bnbPrice))
        });


        provider.send('eth_accounts').then(async (accounts) => {

            if (accounts.length == 0) {
                dispatch(setAccount(""));
                dispatch(setLoadingPage(false));
                return;
            } else {
                const account = accounts[0];
                dispatch(setAccount(account))
                const signer = provider.getSigner();
                const chainId = await signer.getChainId();
                let chain = convertChainId(chainId);

                if (chain != Chains['BEP20'].chainId) {
                    dispatch(setIsSwitch(true));
                } else {
                    const bepPresaleContract = new ethers.Contract(BEP20PRESALE_CONTRACT, BEP20PRESALE_ABI, signer);
                    const bepMAFToken = new ethers.Contract(BEP20MAF_CONTRACT, BEP20MAF_ABI, signer);
                    const bepBUSDToken = new ethers.Contract(BEP20BUSD_CONTRACT, BEP20BUSD_ABI, signer);
                    const bepUSDTToken = new ethers.Contract(BEP20USDT_CONTRACT, BEP20USDT_ABI, signer);

                    dispatch(setBepPresaleContract(bepPresaleContract));
                    dispatch(setBepMAFToken(bepMAFToken));
                    dispatch(setBepBUSDToken(bepBUSDToken));
                    dispatch(setBepUSDTToken(bepUSDTToken));

                    dispatch(setChainId(chain));
                    dispatch(setNetworkName("USDT (BEP20)"))
                    dispatch(setTokenName("USDT"))
                    dispatch(setNetworkCode("BEP20"))
                    bepUSDTToken.balanceOf(account).then((b) => {
                        dispatch(setTokenBalance(ethers.utils.formatEther(b)));
                        dispatch(setLoadingBalance(false));
                    });

                    let b = await bepMAFToken.balanceOf(account);
                    dispatch(setMafBalance(ethers.utils.formatEther(b)));

                }
                setLoadingPage(false);
                return;

            }
        });
    }, []);



    function renderButtons() {
        if (loadingBuy) {
            return (
                <div className="spinner" style={{
                    justifyContent: 'center',
                    justifyItems: 'center',
                    margin: '0 auto'
                }}></div>
            );
        }
        if (isApproved) {
            return (
                <>

                    <div className="col-md-5" id="switch_network_area">
                        <br />
                        <Button title="Approve" className="col-12" onClick={approve_click} />
                    </div>
                    <br />
                    <p style={{ color: 'white', fontSize: "12px", lineHeight: "14px", marginTop: "24px" }}>Bu kontrata {fromAmount ?? 0} adet harcama yetkisi vermelisiniz. Daha fazla bilgi için <a href="https://support.metamask.io/hc/en-us/articles/6174898326683-What-is-a-token-approval-" target="blank">burayı</a> tıklayın</p>
                </>
            );
        }
        if (isSwitch) {
            return (
                <div className="col-md-5" id="switch_network_area">
                    <br />
                    <Button title="Switch Network" className="col-12" onClick={handleSwitchNetwork} />
                </div>
            );
        }

        return (
            <div className="col-md-6" id="buy_area">
                <br />
                <Button title="Buy" className="col-12" onClick={presale} />
            </div>
        );
    }



    function renderView() {
        if (loadingPage) {
            return (
                <div className="spinner" style={{
                    justifyContent: 'center',
                    justifyItems: 'center',
                    margin: '0 auto'
                }}></div>
            );
        }
        if (account == null || account == '') {
            return (
                <div className="col-12" id="connect_wallet">
                    <span style={{ color: 'white', textAlign: 'center', margin: '10px auto' }}>Please connect your MetaMask</span>
                    <Button className="col-12" onClick={connect} title="Connect Wallet" />
                </div>
            );
        } else {

            return (
                <div className="row" id="presalem" style={{ color: '#ffffff !important' }}>

                    <br />
                    <br />
                    <div className="row">
                        <div className="col-6">
                            <label style={{ color: '#fff' }}>{tokenName}<small style={{ color: '#fff' }}>{loadingBalance ? <div className="spinner" style={{
                                height: "12px",
                                width: "12px",
                                display: "inline-block"
                            }}></div> : "(" + tokenBalance + ")"}</small> <button className="btn btn-sm btn-outline-info" onClick={updateCoinBalance}><BiRefresh /></button></label>
                            <input type="number" style={{ color: 'white' }} min="0" value={fromAmount} onInput={(e) => calculateFromAmount(e.target.value)} placeholder="Amount" className="form-control" />

                        </div>
                        <div className="col-6">
                            <label style={{ color: '#fff' }}>MAF <small style={{ color: '#fff' }}>({Number(mafBalance).toFixed(4)})</small> <button className="btn btn-sm btn-outline-info" onClick={updateMafBalance}><BiRefresh /></button></label>
                            <input type="number" style={{ color: 'white' }} min="0" placeholder="Amount" onInput={(e) => calculateToAmount(e.target.value)} value={toAmount} className="form-control" />

                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <small style={{ color: '#fff' }}>Buy With: </small>
                            <div className="custom-select">
                                {provider == null ? <span></span> : <Dropdown
                                    style={{
                                        height: "50px"
                                    }}
                                    className="col-12"
                                    value={network}
                                    onSelect={(e) => handleNetwork(e)} >
                                    <Dropdown.Toggle variant="success" id="network" className="col-12" style={{ height: "50px" }}>
                                        {networkName == '' ? 'Buy With : ' : networkName}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="col-12">
                                        {
                                            networkList.map((mn) => (
                                                <Dropdown.Item key={mn['key']} className={mn['key'] == network ? 'active col-12' : 'col-12'} eventKey={mn['key']}>{mn['title']}</Dropdown.Item>
                                            ))
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                                }

                            </div>
                        </div>

                        {renderButtons()}

                        <div className="row mt-3">

                            <Link style={{
                                color: 'white',
                                textAlign: 'center',
                                justifyContent: 'center',
                                justifyContent: 'center'
                            }} to="#" onClick={() => {
                                window.ethereum
                                    .request({
                                        method: 'wallet_watchAsset',
                                        params: {
                                            type: 'ERC20',
                                            options: {
                                                address: networkCode == 'BEP20' ? BEP20MAF_CONTRACT : '',
                                                symbol: 'MAF',
                                                decimals: 18,
                                                image: 'https://foo.io/token-image.svg',
                                            },
                                        },
                                    })
                                    .then((success) => {
                                        if (success) {
                                        } else {
                                            throw new Error('Something went wrong.');
                                        }
                                    })
                                    .catch(console.error);

                            }}  ><span style={{
                                justifyContent: 'center',
                                textAlign: "center",
                                fontSize: "80%"
                            }} >Click to add MAF tokens to your Metamask wallet</span>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return (
        <section className="pre_sale">
            <div className="video_area">
                <div className="section_page_title">
                </div>
                <video playsInline autoPlay muted loop width="100%">
                    <source src={video_uri} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="presale_content">
                <div className="row" style={{ minWidth: "400px", maxWidth: "60%", margin: "0 auto" }}>
                    <div className="card" style={{ position: 'relative', padding: '30px', margin: '0 auto', background: '#0d1d28', borderRadius: '12px' }}>
                        <div style={{ backgroundColor: '#182029a8', width: '100%', height: '100%', left: '0', top: '0', position: 'absolute', zIndex: '1', borderRadius: '12px' }}></div>
                        <div className="content" style={{ zIndex: '2' }}>
                            {loadingPage ?

                                <div className="spinner" style={{
                                    justifyContent: 'center',
                                    margin: '0 auto'
                                }}></div>
                                :
                                <div >

                                    {renderView()}
                                </div>
                            }

                        </div>
                    </div >
                </div >
            </div >

        </section >
    );
}

export default Presale;